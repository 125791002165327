import { createContext, useContext } from "react";
import { createBrowserHistory, History } from "history";

// Create a new context with a default value of a fallback history object
const fallbackHistory: History = createBrowserHistory();
export const HistoryContext = createContext<History>(fallbackHistory);

const EnvisionHistoryProviderImp = HistoryContext.Provider;

export const EnvisionHistoryProvider = (props: any) => <ContextHackTime>{props.children}</ContextHackTime>;

type ContextHackTimeProps = { children?: React.ReactNode };
const ContextHackTime = ({ children }: ContextHackTimeProps) => {
  const context = useContext(HistoryContext);
  return <EnvisionHistoryProviderImp value={context}>{children}</EnvisionHistoryProviderImp>;
};

export const useEnvisionHistory = () => {
  const contextValue = useContext(HistoryContext);
  if (!contextValue) {
    throw new Error("Cannot find an EnvisionHistoryContext");
  }
  return contextValue as History;
};

type EnvisionHistoryContextBridgeProps = {
  children?: React.ReactNode;
  context: any;
};
export const EnvisionHistoryContextBridge = ({ children, context }: EnvisionHistoryContextBridgeProps) => (
  <EnvisionHistoryProviderImp value={context}>{children}</EnvisionHistoryProviderImp>
);

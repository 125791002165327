export const jsFilesToDownloadFromUIEngine = ["styles-web.js", "themes-web.js"];

export const jsFilesToDownloadFromUIEngineDS = ["ds-core-web.js", "ds-icons-web.js"];

export const jsFilesToDownloadFromUIData = ["table.js", "layoutbuilder.js"];

export const cssFilesToDownload = ["fonts.css"];

export const fontFilesToDownload = [
  "fonts/CircularStd-Book.otf",
  "fonts/CircularStd-Medium.otf",
  "fonts/CircularAir-Light.ttf",
  "fonts/CircularStd-Bold.otf",
  "fonts/CircularStd-BoldItalic.otf"
];

export const generateJsFilesUrlToLoadFromUiEngine = (url: string | undefined) => {
  if (!url) return [];
  return jsFilesToDownloadFromUIEngine.map((fileName) => `${url}/${fileName}`);
};

export const generateJsFilesUrlToLoadFromUiEngineDS = (url: string | undefined) => {
  if (!url) return [];
  return jsFilesToDownloadFromUIEngineDS.map((fileName) => `${url}/${fileName}`);
};

export const generateJsFilesUrlToLoadFromUiData = (url: string | undefined) => {
  if (!url) return [];
  return jsFilesToDownloadFromUIData.map((fileName) => `${url}/${fileName}`);
};

export const generateCssFilesUrlToLoad = (url: string | undefined) => {
  if (!url) return [];
  return cssFilesToDownload.map((fileName) => `${url}/${fileName}`);
};

export const generateFontFilesUrlToLoad = (url: string | undefined) => {
  if (!url) return [];
  return fontFilesToDownload.map((fileName) => `${url}/${fileName}`);
};

export const shouldLoadModule = (module) => {
  let mods = process.env.REACT_APP_LOAD_REMOTE;
  return mods?.split(",").includes(module);
};

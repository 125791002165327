/* eslint-disable @typescript-eslint/no-unused-vars */
import loadjs from "loadjs";
import * as reactP4d from "react";
import ReactDOM, * as reactDOMP4d from "react-dom";
//use this when migrate to Reatc 18
import { createRoot } from "react-dom/client";
import * as three from "three";
import * as reactThreeFiber from "@react-three/fiber";
import * as reactRouterDOMP4d from "react-router-dom";
import { BrowserRouter, Router } from "react-router-dom";
import { createBrowserHistory } from "history";

import { HistoryContext } from "providers/EnvisionHistory";
import {
  generateCssFilesUrlToLoad,
  generateJsFilesUrlToLoadFromUiEngine,
  generateJsFilesUrlToLoadFromUiEngineDS
} from "./helper";
import { getEnv } from "utils/environment";

(window as any).React = reactP4d;
(window as any).ReactDOM = reactDOMP4d;
(window as any).ReactRouterDOM = reactRouterDOMP4d;
(window as any).Three = three;
(window as any).ReactThreeFiber = reactThreeFiber;

const isReady = new Promise((resolve, reject) => {
  // the following should be loaded in the order as required
  let toLoad: string[] = [];

  toLoad = toLoad.concat(generateJsFilesUrlToLoadFromUiEngine(getEnv("REACT_APP_PODIUMUI_URL")));

  toLoad = toLoad.concat(generateJsFilesUrlToLoadFromUiEngineDS(getEnv("REACT_APP_PODIUMUI_URL")));

  // if (shouldLoadModule("lib-npm-ui-data")) {
  //   toLoad = toLoad.concat(generateJsFilesUrlToLoadFromUiData(getEnv(REACT_APP_PODIUMUI_DATA_URL));
  // }
  toLoad = toLoad.concat(generateCssFilesUrlToLoad(getEnv("REACT_APP_PODIUMUI_URL")));

  // fetch the files in parallel but load it in series
  loadjs(toLoad, {
    success: () => {
      resolve(true);
    },
    async: false
  });
});

const mountP4d = (el, { onNavigate, defaultHistory }) => {
  const history = defaultHistory || createBrowserHistory();
  if (onNavigate) {
    history.listen(onNavigate);
  }
  isReady.then(
    async () => {
      const { MainComponent } = await import("./bootstrap");
      const root = createRoot(el);
      root.render(
        <>
          <HistoryContext.Provider value={history}>
            <BrowserRouter>
              <Router history={history}>
                <MainComponent />
              </Router>
            </BrowserRouter>
          </HistoryContext.Provider>
        </>
      );
    },
    () => {}
  );

  return {
    onParentNavigate({ pathname: nextPathname, search: nextSearch }) {
      const { pathname, search } = history.location;
      if (pathname !== nextPathname || nextSearch !== search) {
        history.push(nextPathname + nextSearch);
      }
    }
  };
};

// If we are in development and in isolation,
// call mount immediately
const root = document.querySelector("#root");
mountP4d(root, { onNavigate: undefined, defaultHistory: createBrowserHistory() });
